import * as React from 'react';
import { screenType } from '../App';
import { Input, Button } from '../components/form';
import { environment, IFormConfiguration, IInstitution } from '../types';
import { BackButton } from './BankListScreen';
import { bankImages } from '../banks';
import { Flex, Stack } from '../components/layout';
import { hermes } from '../utils/hermes';
import theme from '../theme';
import useForm, { IUseForm } from '../utils/useForm';
import Form from '../components/form/Form';

type PageViews = 'loading' | 'initial' | 'otp';

interface IRenderFormItemProps {
  formItem: IFormConfiguration;
  form: IUseForm;
}

interface IProps {
  goToScreen: (screen: screenType) => void;
  institution: IInstitution;
  publicKey: string;
  setContractCode: (code: string) => void;
  env: environment;
}

interface IAlertMessage {
  message: string;
  type: 'error' | 'warning' | 'success';
}

const AlertMessage: React.FC<IAlertMessage> = ({ message, type }) => {
  const color = {
    error: {
      bg: theme.colors.red[500],
      fg: theme.colors.white,
    },

    warning: {
      bg: theme.colors.orange[400],
      fg: theme.colors.white,
    },

    success: {
      bg: theme.colors.green[500],
      fg: theme.colors.white,
    },
  };

  return (
    <Flex
      ai="center"
      jc="center"
      css={{
        margin: '20px 20px -25px 20px',
        borderRadius: 6,
        padding: '10px',
        backgroundColor: color[type].bg,

        p: {
          color: color[type].fg,
          fontFamily: 'Work Sans',
          fontWeight: 600,
          fontSize: '14px',
        },
      }}
    >
      <p>{message}</p>
    </Flex>
  );
};

const Loader = () => {
  return (
    <Flex
      ai="center"
      jc="center"
      stack
      css={{
        height: '80%',

        '.loading-text': {
          fontFamily: 'Work Sans',
          fontWeight: 600,
          fontSize: '18px',
          marginTop: 20,
        },
      }}
    >
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <p className="loading-text">Loading...</p>
    </Flex>
  );
};

function renderFormItem({ formItem, form }: IRenderFormItemProps) {
  let view: any;
  const name = formItem.label
    ?.split(' ')
    ?.map((item) => item.toLowerCase())
    ?.join('_');

  switch (formItem.type) {
    case 'text':
      view = (
        <Input
          label={formItem.label}
          placeholder={formItem.placeholder}
          isRequired={formItem.required}
          error={form.errors[name]}
          onBlur={form.onBlur}
          name={name}
          value={form.inputState[name]}
          onChange={form.onChange}
        />
      );
      break;

    case 'button':
      view = (
        <Button type="submit" className="btn continue-btn">
          {formItem.value}
        </Button>
      );
      break;

    default:
      break;
  }

  return view;
}

const BankFormScreen: React.FC<IProps> = ({
  goToScreen,
  institution,
  publicKey,
  setContractCode,
  env,
}) => {
  if (!institution || !institution.meta) goToScreen('bank-list');

  const initialForm = useForm({
    fields: generateInitialState(
      institution.meta.link_configuration.initial as IFormConfiguration[],
    ),
  });

  const OTPForm = useForm({
    fields: generateInitialState(institution.meta.link_configuration.otp),
  });

  const [activeView, setActiveView] = React.useState<PageViews>('initial');
  const [sessionId, setSessionId] = React.useState<string>('');
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  async function submitOTPCode() {
    setActiveView('loading');
    setErrorMessage('');

    try {
      const { data } = await hermes({
        url: '/links/otp',
        env,
        data: {
          session_id: sessionId || 'c0thraojguo98tomto7g',
          otp: OTPForm.inputState['otp'],
        },
      });

      if (data.message === 'link complete') {
        setContractCode(data.data.code);
      }
      goToScreen('status');
    } catch (error) {
      console.log('error');
      setActiveView('otp');
      setErrorMessage('Invalid OTP');
    }
  }

  async function submitInitialForm() {
    setActiveView('loading');
    setErrorMessage('');


    try {
      const request = await hermes({
        url: '/links/new',
        env,
        data: {
          username: initialForm.inputState['phone_number'],
          password: initialForm.inputState['pin'],
          bank_id: institution.id,
          public_key: publicKey,
        },
      });
      
      if (request?.data.message === 'link sucessful') {
        setSessionId(request?.data?.data?.session_id);
        setActiveView('otp');
      }
    } catch (error) {
      console.log('error');
      setActiveView('initial');
      setErrorMessage('Invalid credentials');
    }
  }

  const LogoElement = React.createElement(bankImages[institution.code], {
    // @ts-ignore
    className: 'brank-logo',
  });

  function fillSandboxCredentials() {
    const { setInputField } = initialForm;
    setInputField('phone_number', 'user_test');
    setInputField('pin', 'pass_test');
  }
  function fillSandboxOTP() {
    const { setInputField } = OTPForm;
    setInputField('otp', '1234');
  }

  return (
    <div className="bank-form-container">
      <BackButton onClick={() => goToScreen('bank-list')} />
      <div className="bank-form-header">
        <Flex jc="center" ai="center">
          <div className="brank-logo-container">{LogoElement}</div>
        </Flex>
        <div className="bf-bank-details">
          <div
            className="institution-form-header"
            css={{
              '*': {
                fontWeight: 800,
              },
            }}
          >
            <p>
              Sign into your
              <span> {institution?.name}</span>
            </p>
          </div>
        </div>
      </div>

      {env === 'sandbox' && activeView === 'initial' && (
        <div className="sandbox-autofill" onClick={fillSandboxCredentials}>
          Fill with sandbox credentials
        </div>
      )}

      {env === 'sandbox' && activeView === 'otp' && (
        <div className="sandbox-autofill" onClick={fillSandboxOTP}>
          Fill with sandbox OTP
        </div>
      )}

      {errorMessage && <AlertMessage type="error" message={errorMessage} />}

      {activeView === 'initial' && (
        <Form form={initialForm} onSubmit={submitInitialForm}>
          <div className="bf-form">
            <Stack spacing={20}>
              {institution.meta.link_configuration.initial.map(
                (formItem: IFormConfiguration, index: number) => {
                  return (
                    <div key={index}>
                      {renderFormItem({
                        formItem,
                        form: initialForm,
                      })}
                    </div>
                  );
                },
              )}
            </Stack>
          </div>
        </Form>
      )}

      {activeView === 'otp' && (
        <Form form={OTPForm} onSubmit={submitOTPCode}>
          <div className="bf-form">
            {env !== 'sandbox' && (
              <p
                css={{
                  marginBottom: '20px',
                  fontFamily: 'Work Sans',
                  fontWeight: 600,
                  color: theme.colors.gray[800],
                  b: { fontWeight: 'bold' },
                }}
              >
                Enter the code sent to you at{' '}
                <b
                  css={{
                    fontFamily: 'Work Sans',
                    fontWeight: 600,
                    color: theme.colors.secondary,
                  }}
                >
                  {initialForm?.inputState['phone_number']}
                </b>{' '}
              </p>
            )}

            <Stack spacing={20}>
              {institution.meta.link_configuration.otp.map(
                (formItem: IFormConfiguration, index: number) => {
                  return (
                    <div key={index}>
                      {renderFormItem({
                        formItem,
                        form: OTPForm,
                      })}
                    </div>
                  );
                },
              )}
            </Stack>
          </div>
        </Form>
      )}

      {activeView === 'loading' && <Loader />}
    </div>
  );
};

function generateInitialState(config: IFormConfiguration[]) {
  const formState = {};

  config.map((formItem: IFormConfiguration) => {
    if (formItem.type === 'text') {
      const name = formItem.label
        .split(' ')
        .map((item) => item.toLowerCase())
        .join('_');
      formState[name] = '';
    }
  });

  return formState;
}

export default BankFormScreen;
