import * as React from 'react';
import { screenType } from '../App';
import Logo from '../images/logo';
import { IClient, IInstitution } from '../types';
import { Flex } from '../components/layout';
import Icon from '../components/icon/Icon';
import theme from '../theme';
import Button from '../components/form/button/Button';

interface IProps {
  institution: IInstitution;
  goToScreen: (screen: screenType) => void;
  client: IClient;
  onSuccess: () => void;
}

interface ISuccessProps {
  client: IClient;
  onSuccess: () => void;
  institution: IInstitution;
}

const SuccessScreen: React.FC<ISuccessProps> = ({ institution, client, onSuccess }) => {
  return (
    <>
      <div className="ls">
        <Icon
          icon={['fad', 'check-circle']}
          size="6x"
          color={theme.colors.secondary}
          css={{
            marginTop: 'auto',
            '.fa-secondary': {
              fill: theme.colors.green[300],
            },
          }}
        />
        <div className="ls-info">
          <p className="ls-info-header">Log in successful!</p>
          <p className="ls-info-description">
            Your {institution.name} account has been linked successfully with
            <span> {client.name}</span>
          </p>
        </div>
        <div className="ls-cta-container">
          <Button onClick={onSuccess}>Continue</Button>
        </div>
      </div>
    </>
  );
};

const LoginStatusScreen: React.FC<IProps> = ({ institution, onSuccess, goToScreen, client }) => {
  if (!institution) goToScreen('bank-list');
  return (
    <div className="ls-container">
      <Flex jc="center" ai="center">
        <div className="brank-logo-container">
          <Logo className="brank-logo" />
        </div>
      </Flex>
      <SuccessScreen institution={institution} client={client} onSuccess={onSuccess} />
    </div>
  );
};

export default LoginStatusScreen;
