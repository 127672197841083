import * as React from 'react';
import { screenType } from '../App';
import Logo from '../images/logo';
import { Input } from '../components/form';

import { bankImages } from '../banks';
import { IInstitution } from 'types';
import { Flex } from '../components/layout';
import Icon from '../components/icon/Icon';
import theme from '../theme';

interface IInstitutionProps extends IInstitution {
  onClick: () => void;
}

const Institution: React.FC<IInstitutionProps> = ({ name, code, meta, onClick }) => {
  const LogoElement = React.createElement(bankImages[code]);

  return (
    <div
      className="bank"
      onClick={() => {
        if (meta?.link_configuration?.initial) {
          onClick();
        }
      }}
    >
      <div className="bank-img-container">{LogoElement}</div>
      <div className="bank-details">
        <div className="bank-name">{name}</div>
      </div>
    </div>
  );
};

function SearchIcon() {
  return (
    <Flex
      ai="center"
      jc="center"
      css={{
        background: theme.colors.normalGray[100],
        padding: '15px',
        borderRadius: '50%',
        marginBottom: 20,
        width: 80,
        height: 80,

        svg: {
          margin: 0,
        },
      }}
    >
      <Icon
        css={{ margin: 0 }}
        color={theme.colors.warmGray[800]}
        icon={['fad', 'search']}
        size="2x"
      />
    </Flex>
  );
}

const BackButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button className="back-btn" onClick={onClick}>
      <svg width="20px" height="20px" viewBox="0 0 512 512">
        <title>Chevron Back</title>
        <path
          fill="none"
          stroke="#1F2937"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="48"
          d="M328 112L184 256l144 144"
        />
      </svg>
    </button>
  );
};

interface IProps {
  goToScreen: (screen: screenType) => void;
  selectInstitution: (bank: IInstitution) => void;
  institutions: IInstitution[];
}

const BankListScreen: React.FC<IProps> = ({ goToScreen, selectInstitution, institutions }) => {
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [suggestions, setSuggestions] = React.useState<IInstitution[]>([]);

  function handleInputChange(e: React.SyntheticEvent<HTMLInputElement>) {
    const { value } = e.currentTarget;
    setSearchValue(value);

    const regex = new RegExp(`^${value.toLowerCase()}`, 'i');
    const suggestions = institutions.filter((institution: IInstitution) =>
      regex.test(institution.name.toLowerCase()),
    );

    setSuggestions(suggestions);
  }

  return (
    <div className="bank-list-container">
      <BackButton onClick={() => goToScreen('welcome')} />

      <Flex jc="center" ai="center">
        <div className="brank-logo-container">
          <Logo className="brank-logo" />
        </div>
      </Flex>

      <div className="bank-search-input">
        <div className="bank-search-header">Select your institution</div>
        <Input
          className="search-input"
          type="text"
          placeholder="Search"
          value={searchValue}
          onChange={handleInputChange}
        />
      </div>

      <div className="bank-list">
        {!searchValue &&
          institutions.map((institution: IInstitution, index) => {
            return (
              <Institution
                {...institution}
                key={index}
                onClick={() => {
                  goToScreen('form');
                  selectInstitution(institution);
                }}
              />
            );
          })}

        {searchValue &&
          // suggestions.length > 0 &&
          suggestions.map((institution: IInstitution, index: number) => {
            return (
              <Institution
                {...institution}
                key={index}
                onClick={() => {
                  goToScreen('form');
                  selectInstitution(institution);
                }}
              />
            );
          })}

        {searchValue && suggestions.length === 0 && (
          <div className="bank-search-no-entries">
            <SearchIcon />
            <p>
              No Institution was found for <br /> <span>{searchValue}</span>{' '}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export { BackButton };
export default BankListScreen;
