import { ButtonProps } from '../../../types';
import generateStyles from './button.styles';

const Button = ({ children, onClick }: ButtonProps) => {
  return (
    <button css={generateStyles()} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
