import * as React from 'react';
import { screenType } from '../App';
import Button from '../components/form/button/Button';
import Icon from '../components/icon/Icon';
import { Flex } from '../components/layout';
import { IClient } from '../types';
import theme from '../theme';
import Logo from '../images/logo';

interface IProps {
  goToScreen: (screen: screenType) => void;
  client: IClient;
}

const WelcomeScreen: React.FC<IProps> = ({ goToScreen, client }) => {
  return (
    <div className="welcome-container">
      <div className="brank-logo-container">
        <Logo className="brank-logo" />
      </div>

      <p className="header">
        <span>{client?.name}</span> uses Brank to connect with your bank accounts
      </p>

      <div className="section-container">
        <div className="section">
          <Flex>
            <Flex className="icon-container">
              <Icon icon={['fad', 'lock-alt']} fixedWidth color={theme.colors.gray[500]} />
            </Flex>
            <Flex stack className="section-info-container">
              <p className="section-header">Secure</p>
              <p className="section-description">
                Encryption helps protect your personal financial data.
              </p>
            </Flex>
          </Flex>
        </div>

        <div className="section">
          <Flex>
            <Flex className="icon-container">
              <Icon icon={['fad', 'eye-slash']} color={theme.colors.gray[500]} />
            </Flex>
            <Flex stack className="section-info-container">
              <p className="section-header">Private</p>
              <p className="section-description">
                Your credentials will never be made accessible to{' '}
                <span
                  css={{ textTransform: 'capitalize', fontFamily: 'Work Sans', fontWeight: 600 }}
                >
                  {client?.name}
                </span>
              </p>
            </Flex>
          </Flex>
        </div>

        <div className="section">
          <Flex>
            <Flex className="icon-container">
              <Icon icon={['fad', 'shield-alt']} color={theme.colors.gray[500]} fixedWidth />
            </Flex>
            <Flex stack className="section-info-container">
              <p className="section-header">Protected</p>
              <p className="section-description">
                Brank or{' '}
                <span
                  css={{ textTransform: 'capitalize', fontFamily: 'Work Sans', fontWeight: 600 }}
                >
                  {client?.name}{' '}
                </span>
                doesn't have access to move your funds.
              </p>
            </Flex>
          </Flex>
        </div>
      </div>

      <div className="cta-btn-container">
        <p>
          By selecting “Continue” you agree to the{' '}
          <a href="https://withbrank.com/privacy" target="_blank">
            Brank End User Privacy Policy
          </a>
        </p>

        <Button onClick={() => goToScreen('bank-list')}>Continue</Button>
      </div>
    </div>
  );
};

export default WelcomeScreen;
