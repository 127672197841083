const validations: any = {
  phone_number: function (value: string) {
    if (!value) {
      return 'please enter a valid phone number';
    }
  },

  pin: function (value: number | string) {
    if (!value) {
      return 'please enter a valid PIN number';
    }
  },

  otp: function (value: number | string) {
    if (!value) {
      return 'OTP is required';
    }
  },
};

export default validations;
