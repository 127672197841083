import * as React from 'react';

interface IProps {
  errorMessage: string;
}

const ErrorScreen: React.FC<IProps> = ({ errorMessage }) => {
  return (
    <div className="error-container">
      <svg height={50} viewBox="0 0 512.14 512.14" width={50}>
        <path d="M268.97 46.07h-25.8L0 443.83l13.72 22.24h484.7l13.72-22.24z" fill="#feb556" />
        <path d="M512.14 443.83l-13.72 22.24H256.05v-420h12.92z" fill="#fd9126" />
        <path d="M241.07 371.07h30v30h-30zM241.07 176.07h30v160h-30z" fill="#2a4979" />
        <g fill="#202a58">
          <path d="M256.05 176.07h15.02v160h-15.02zM256.05 371.07h15.02v30h-15.02z" />
        </g>
      </svg>
      <h4>An error happeend</h4>
      <div className="error-message">{errorMessage}</div>
    </div>
  );
};

export default ErrorScreen;
