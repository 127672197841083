import { StyleFunction } from '../../../types/index';
import theme from '../../../theme/index';

const generateStyles: StyleFunction = () => {
  return {
    height: '50px',
    width: '100%',
    borderRadius: '6px',
    border: '0',
    margin: '20px 0',
    backgroundColor: theme.colors.primary,
    boxShadow: theme.shadows.md,
    cursor: 'pointer',
    outline: 'none',
    transition: 'all 0.2s ease-in-out',
    color: 'white',
    fontWeight: 500,
    fontSize: '15px',

    '&:hover': {
      boxShadow: '0px 0px 0px 4px rgb(0 0 0 / 20%)',
    },
  };
};

export default generateStyles;
