import StandardCharteredLogo from './images/standchart';
import FNBLogo from './images/fnb';
import FidelityLogo from './images/fidelity';
import { ComponentClass, FunctionComponent } from 'react';

export const inputType: {
  [key: string]: string;
} = {
  'Phone Number': 'number',
  email: 'email',
  password: 'password',
  PIN: 'password',
  Username: 'text',
};

type Component = string | FunctionComponent<{}> | ComponentClass<{}>;

const bankImages: Record<string, Component> = {
  fnb: FNBLogo,
  scb: StandardCharteredLogo,
  fb: FidelityLogo,
};

export { bankImages };
