import 'regenerator-runtime/runtime';
import * as React from 'react';

// screens
import {
  WelcomeScreen,
  ErrorScreen,
  BankListScreen,
  BankFormScreen,
  StatusScreen,
  //   OTPScreen,
} from './screens';

import { hermes } from './utils/hermes';
import Icon from './components/icon/Icon';
import Flex from './components/layout/Flex';
import { IInstitution, IClient, environment } from 'types';
import theme from './theme';

const CloseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <div id="closeBtn" className="close-button" tabIndex={0} onClick={() => onClick()}>
      <svg width="20px" height="20px" viewBox="0 0 512 512">
        <path
          fill="none"
          stroke="#1F2937"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="48"
          d="M368 368L144 144M368 144L144 368"
        />
      </svg>
    </div>
  );
};

const Footer = () => (
  <div className="brank-tagline">
    <a href="https://withbrank.com" target="_blank" className="secured-by-brank">
      <Icon icon={['fad', 'lock-alt']} color="white" />
      <p>Secured by Brank</p>
    </a>
  </div>
);

const Container = ({ children }) => {
  return (
    <div className="modal">
      <div className="modal-container">{children}</div>
      <Footer />
    </div>
  );
};

export type screenType = 'welcome' | 'bank-list' | 'form' | 'status' | 'otp' | 'error';

const App = () => {
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [showSpinner, setShowSpinner] = React.useState<boolean>(true);
  const [activeView, setActiveView] = React.useState<screenType>('welcome');
  const [institutions, setInstitutions] = React.useState<IInstitution[]>([]);
  const [selectedInstitution, setSelectedInstitution] = React.useState<IInstitution | any>({});
  const [client, setClient] = React.useState<IClient | null>(null);
  const [contractCode, setContractCode] = React.useState<string>('');

  const parentURL: URL = new URL(window.location.href);
  let params: URLSearchParams = new URLSearchParams(parentURL.search);
  const key = params.get('key');
  const env: string = params.get('env');

  async function fetchInstitutions() {
    try {
      const { data } = await hermes({
        url: '/institutions',
        method: 'GET',
        env: env as environment,
      });

      if (data?.data?.institutions) {
        setInstitutions(data.data?.institutions);
      }
    } catch (error) {
      setActiveView('error');
    }
  }

  async function getApplicationDetails() {
    try {
      const { data } = await hermes({
        url: `/applications/pk/${key}`,
        method: 'GET',
        env: env as environment,
      });

      const client = data?.data.app;

      if (!client) {
        setActiveView('error');
      }
      setActiveView('welcome');
      setShowSpinner(false);
      setClient(client);
    } catch (error) {
      setErrorMessage('Please enter a valid public key');
      setShowSpinner(false);
      setActiveView('error');
    }
  }

  React.useEffect(() => {
    if (!key) {
      setErrorMessage('Public key is required');
      setShowSpinner(false);
      setActiveView('error');
    }

    if (key) {
      getApplicationDetails();
      fetchInstitutions();
    }
  }, [key]);

  function goToScreen(screen: screenType) {
    setActiveView(screen);
  }

  function closeModal() {
    const clientURL: string = params.get('clientUrl');

    if (!key) {
      return;
    }
    setActiveView('welcome');

    setErrorMessage('');
    setSelectedInstitution({});
    setContractCode('');
    window?.parent.postMessage({ type: 'brank.widget.close' }, clientURL);
  }

  function completeFlow() {
    const clientURL: string = params.get('clientUrl');

    window.parent.postMessage(
      { type: 'brank.widget.link_successful', code: contractCode },
      clientURL,
    );
  }

  let currentScreen: React.ReactNode;

  switch (activeView) {
    case 'welcome':
      currentScreen = (
        <Container>
          <WelcomeScreen client={client} goToScreen={goToScreen} />
        </Container>
      );

      break;

    case 'bank-list':
      currentScreen = (
        <Container>
          <BankListScreen
            selectInstitution={(bank: IInstitution) => setSelectedInstitution(bank)}
            goToScreen={goToScreen}
            institutions={institutions}
          />
        </Container>
      );
      break;

    case 'form':
      currentScreen = (
        <Container>
          <BankFormScreen
            env={env as environment}
            publicKey={key}
            goToScreen={goToScreen}
            institution={selectedInstitution}
            setContractCode={(code: string) => setContractCode(code)}
          />
        </Container>
      );
      break;

    case 'status':
      currentScreen = (
        <Container>
          <StatusScreen
            client={client}
            onSuccess={completeFlow}
            goToScreen={goToScreen}
            institution={selectedInstitution}
          />
        </Container>
      );
      break;

    case 'error':
      currentScreen = (
        <div className="error-modal-container">
          <ErrorScreen errorMessage={errorMessage} />
          <Footer />
        </div>
      );
      break;

    // case 'otp':
    //   currentScreen = (
    //     <Container>
    //       <OTPScreen submitForm={submitOTPForm} />
    //     </Container>
    //   );
    //   break;

    default:
      currentScreen = (
        <div className="error-modal-container">
          {/* <ErrorScreen errorMessage="An error occured, please reload the window" /> */}
          <Footer />
        </div>
      );
      break;
  }

  // @ts-ignore
  const showBanner = client?.environment === 'sandbox' || client?.environment === 'development';

  return (
    <div className="container" id="brank-connect">
      <>
        {showSpinner ? (
          <div className="lds-ripple">
            <div />
          </div>
        ) : (
          <>
            {showBanner && (
              <Flex
                jc="center"
                ai="center"
                css={{
                  marginBottom: 'auto',
                  zIndex: 9999,
                  width: '100%',
                  backgroundColor: theme.colors.black,
                  padding: '15px',

                  p: {
                    fontWeight: 600,
                    fontFamily: theme.typography.fonts.sans,
                    color: 'white',
                    marginRight: 38,
                  },
                }}
              >
                <p>You are currently in {client?.environment} mode</p>
              </Flex>
            )}
            <div className="c-centered" css={{ marginBottom: showBanner ? 'auto' : 0 }}>
              {currentScreen}
              <CloseButton onClick={closeModal} />
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default App;
