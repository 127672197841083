import * as React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCaretDown,
  faBell,
  faMoneyBill,
  faMoneyBillWaveAlt,
  faLongArrowDown,
  faLongArrowUp,
  faTimes,
  faPlus,
  faArrowUp,
  faPen,
  faEllipsisH,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
  faInfo,
  faWallet as walletSolid,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faEye,
  faArrowLeft,
  faArrowRight,
  faAt,
  faCalendar,
  faShield,
  faLifeRing,
  faQuestionCircle,
  faTrash,
  faPaperclip,
  faUndo,
  faFile as solidFile,
  faBars,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faHomeLgAlt,
  faThLarge,
  faCreditCard,
  faExchangeAlt,
  faWallet,
  faFileAlt,
  faListAlt,
  faUserCircle,
  faMailbox,
  faBadgeCheck,
  faUniversity,
  faMobileAndroid,
  faBan,
  faBadgeDollar,
  faShieldAlt,
  faBadgePercent,
  faWandMagic,
  faCreditCardBlank,
  faLockAlt,
  faPaperPlane,
  faFileChartLine,
  faUnlockAlt,
  faReceipt,
  faChartPieAlt,
  faCog,
  faSignOut,
  faTransporterEmpty,
  faMeteor,
  faColumns,
  faCalendarWeek,
  faCalendarExclamation,
  faPercent,
  faTimes as dutoToneTimes,
  faUserCrown,
  faSparkles,
  faInfoCircle as duoToneInfoCircle,
  faCommentTimes,
  faExclamationTriangle as dutoToneExclamationTriangle,
  faClock,
  faQuestionCircle as duoToneQuestionCircle,
  faHomeHeart,
  faExternalLinkSquare,
  faGift,
  faRocketLaunch,
  faBox,
  faChartBar,
  faSignOutAlt,
  faMoneyCheckAlt,
  faUserShield,
  faUserSecret,
  faEyeSlash,
  faSearch,
  faCheckCircle
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faPlusCircle,
  faTimes as regularTimes,
  faPlus as regularPlus,
  faFile as regularFile,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

export function startIconLibrary(): void {
  library.add(
  faEyeSlash,
    faUserShield,
    faUserSecret,
    faChartBar,
    faSignOutAlt,
    faMoneyCheckAlt,
    faBox,
    faRocketLaunch,
    faExternalLinkSquare,
    dutoToneExclamationTriangle,
    faArrowLeft,
    faGift,
    faArrowRight,
    faChevronDown,
    faCaretDown,
    faHomeLgAlt,
    faThLarge,
    faCreditCard,
    faExchangeAlt,
    faWallet,
    faFileAlt,
    faListAlt,
    faUserCircle,
    faBell,
    faMoneyBill,
    faMoneyBillWaveAlt,
    faLongArrowDown,
    faLongArrowUp,
    faMailbox,
    faHomeHeart,
    faBadgeCheck,
    faTimes,
    faPlus,
    faUniversity,
    faMobileAndroid,
    faBan,
    faArrowUp,
    faBadgeDollar,
    faShieldAlt,
    faBadgePercent,
    faWandMagic,
    faCreditCardBlank,
    faLockAlt,
    faFileChartLine,
    faCheckCircle,
    faPen,
    faUnlockAlt,
    faEllipsisH,
    faReceipt,
    faMoneyCheckAlt,
    faChartPieAlt,
    faExclamationCircle,
    faExclamationTriangle,
    faInfoCircle,
    walletSolid,
    faCog,
    faSignOut,
    faTransporterEmpty,
    faChevronRight,
    faChevronLeft,
    faMeteor,
    faColumns,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faSearch,
    faCalendarWeek,
    faCalendarExclamation,
    faPercent,
    dutoToneTimes,
    faInfo,
    faEye,
    faEyeSlash,
    regularTimes,
    faPaperPlane,
    faUserCrown,
    faAt,
    faCalendar,
    faPlusCircle,
    faSparkles,
    regularPlus,
    duoToneInfoCircle,
    faShield,
    faLifeRing,
    faQuestionCircle,
    faCommentTimes,
    faTrash,
    faPaperclip,
    faUndo,
    regularFile,
    solidFile,
    faClock,
    duoToneQuestionCircle,
    faBars,
  );
}

export interface IProps extends FontAwesomeIconProps {
  className?: string;
}

const Icon: React.FC<IProps> = ({ icon, size, color, style, className ...rest}) => {
  return (
    <FontAwesomeIcon
      className={className}
      style={style}
      color={color}
      size={size}
      icon={icon}
      {...rest}
    ></FontAwesomeIcon>
  );
};

export default Icon;
