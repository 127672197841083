import axios, { AxiosPromise } from 'axios';

// our network system powered by the Olympian of god of transport
const baseUrl: string = 'https://api.withbrank.com';
const sandboxURL: string = 'https://sandbox.withbrank.com';

interface IHermes {
  data?: any;
  url?: string;
  method?: 'POST' | 'GET' | 'PUT';
  token?: string;
  env?: 'sandbox' | 'production' | 'development';
}

export function hermes({ data, url, method = 'POST', env = 'sandbox' }: IHermes): AxiosPromise {
  const requestURL: string = env === 'sandbox' ? `${sandboxURL}${url}` : `${baseUrl}${url}`;

  return axios({
    data,
    url: requestURL,
    method,
  });
}
