import { css, Global } from '@emotion/react';
import theme from './index';

const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        @import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,300;1,300&display=swap');

        :root {
          --primary-green: #059669;
          --primary-black: #1f2937;
          --primary-text-color: #4b5563;
          --primary-gray: #9ca3af;
          --primary-grayLigther: #f9fafb;
          --disabled-color: #e5e7eb;
          --primary-error: #ef4444;
          --btn-background: linear-gradient(180deg, #10b981, #059669);
        }

        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          letter-spacing: 0.2px;
          -webkit-font-smoothing: antialiased;
          font-weight: 350;
          font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
            Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          /* outline: 1px dotted red; */
        }

        body,
        html {
          letter-spacing: 0.2px;
          -webkit-font-smoothing: antialiased;
          font-weight: 300;
          font-family: var(--regular-font);
        }

        .container {
          position: fixed;
          top: 0;
          left: 0;
          height: 100vh;
          width: 100vw;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          z-index: 999999;
        }

        .c-centered {
          display: flex;
          justify-content: center;
          align-items: flex-start;
        }

        .modal {
          animation: scaleUp 1s;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .close-button {
          display: flex;
          padding: 2px;
          background-color: white;
          border-radius: 4px;
          margin-left: 10px;
          cursor: pointer;
          border: 2px solid white;
          animation: scaleUp 1s;
        }

        .close-button:focus {
          border: 2px solid var(--primary-green);
          outline: none;
        }

        .welcome-container {
          padding: 20px 20px;
          height: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          position: relative;
        }

        .modal-container {
          width: 350px;
          height: 550px;
          background-color: white;
          border-radius: 6px;
        }

        .brank-logo-container {
          width: 60px;
          height: 60px;
          position: absolute;
          top: -30;
          padding: 10px;
          border-radius: 50%;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
          z-index: 2;
          background-color: white;
        }

        .brank-logo {
          margin-bottom: 20px;
          width: 100%;
          height: 100%;
        }

        .cta-btn-container {
          margin-top: auto;
        }

        .cta-btn-container p {
          font-size: 12px;
          margin-bottom: 10px;
          text-align: center;
          color: var(--primary-text-color);
        }

        .cta-btn-container p a {
          color: var(--primary-green);
          font-weight: 600;
          font-family: 'Work Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI',
            Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          font-size: 12px;
          text-decoration: underline;
        }

        .header {
          font-size: 17px;
          text-align: center;
          margin-top: 40px;
          padding: 0 30px;
          font-family: var(--header-font);
          letter-spacing: -0.6px;
        }

        .header span {
          color: var(--primary-green);
          font-weight: 600;
          text-transform: capitalize;
        }

        .btn {
          border: 0;
          padding: 15px 0;
          border-radius: 6px;
          width: 100%;
          cursor: pointer;
          font-size: 16px;
        }

        .continue-btn {
          margin-top: auto;
          background-image: var(--btn-background);
          color: white;
          font-weight: 500;
        }

        .continue-btn:disabled {
          background: var(--disabled-color);
          color: var(--primary-black);
          box-shadow: none;
          text-shadow: none;
        }

        .section-container {
          margin: auto 0;
        }

        .section {
          margin: 20px 0;
          display: flex;
        }

        .section-info-container {
          margin-left: 10px;
        }

        .section-header {
          font-size: 15px;
          font-weight: 500;
          color: var(--primary-black);
        }

        .section-description {
          font-size: 14px;
          margin: 8px 0;
          color: var(--primary-text-color);
          font-weight: 600;
          font-family: 'Work Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI',
            Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        }

        .bank-list-container {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          /* overflow: hidden; */
          position: relative;
        }

        .bank-list-container > * {
          /* outline: 1px dotted red !important; */
        }

        .bank-list-header {
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--primary-grayLigther);
        }

        .back-btn {
          background-color: transparent;
          border: 0;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          height: 40px;
          padding: 0 20px;
        }

        .brank-logo-bank {
          width: 60px;
          height: 15px;
        }

        .bank-search-header {
          font-weight: 600;
          letter-spacing: -0.6px;
          font-size: 19px;
          margin-bottom: 10px;
          padding-top: 20px;
          color: var(--primary-black);
        }

        .bank-search-input {
          padding: 0px 20px 20px 20px;
          background-color: #e4e4e7;
          padding-top: 30px;
          border-radius: 6px 6px 0 0;
        }

        .search-input > input {
          background-color: white !important;
          /* outline: 1px solid red !important; */
        }

        .bank-search-no-entries {
          width: 100%;
          height: 75%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }

        .bank-search-no-entries p {
          text-align: center;
        }

        .bank-search-no-entries p span {
          font-family: var(--header-font);
          margin-top: 5px;
          display: block;
        }

        .bank-search-no-entries svg {
          width: 60px;
          height: 60px;
          fill: var(--primary-black);
          margin-bottom: 30px;
        }

        .bank-list {
          flex: 1 1 auto;
          overflow-x: hidden;
          overflow-y: scroll;
          height: 100%;
        }

        .bank {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
          border-bottom: 1px solid #f3f4f6;
          padding: 5px 6px;
        }

        .bank:hover {
          background-color: #f9fafb;
        }

        .bank-img-container {
          width: 60px;
          height: 60px;
          margin-right: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .bank-img-container svg {
          width: 40px;
          height: 40px;
          object-fit: contain;
        }

        .bank-url {
          font-size: 13px;
          color: var(--primary-gray);
        }

        .bank-name {
          font-size: 15px;
          color: var(--primary-black);
        }

        .bank-form-container {
          position: relative;
        }

        .bank-form-header {
          background-color: var(--primary-grayLigther);
          padding-bottom: 30px;
          padding: 0px 20px 20px 20px;
          background-color: #e4e4e7;
          padding-top: 30px;
          border-radius: 6px 6px 0 0;
        }

        .sandbox-autofill {
          margin: 20px 20px 0 20px;
          box-shadow: ${theme.shadows.sm};
          text-align: center;
          padding: 10px;
          border-radius: 6px;
          font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
            Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          background-color: ${theme.colors.blueGray[600]};
          font-weight: 600;
          color: white;
          cursor: pointer;
          font-size: 14px;
        }

        .bf-bank-details {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-top: 20px;
        }

        .bf-bank-details svg {
          width: 50px;
          height: 50px;
          margin: 10px 0;
        }

        .bf-bank-url {
          font-size: 13px;
          color: #9ca3af;
          font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
            Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        }

        .institution-form-header {
          margin: 10px 0;
          font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
            Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          font-weight: 800;
          letter-spacing: -0.6px;
          font-size: 19px;
          color: var(--primary-black);
        }

        .bf-form {
          padding: 0 20px;
          margin-top: 50px;
        }

        .bf-form button {
          margin-top: 10px;
          font-size: 16px;
        }

        .bf-form-otp {
          width: 100%;
        }

        .bf-footnote {
          color: var(--primary-text-color);
          text-align: center;
          margin-top: 10px;
          text-decoration: underline;
          cursor: pointer;
        }

        .ls-container {
          height: 100%;
          position: relative;
          background-color: var(----primary-black);
        }

        .ls {
          padding: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 100%;
          background-color: var(----primary-black);
        }

        .ls svg {
          width: 100px;
          height: 100px;
        }

        .ls .success {
          margin-bottom: 20px;
          width: 80px;
          height: 80px;
        }

        .ls-cta-container {
          width: 100%;
          margin-top: auto;
        }

        .ls-cta-container .link {
          text-align: center;
          margin-top: 5px;
          cursor: pointer;
          text-decoration: underline;
          color: var(--primary-text-color);
        }

        .ls-info {
          margin-top: 10px;
        }

        .ls-info-header {
          text-align: center;
          font-weight: 800;
          letter-spacing: -0.6px;
          font-size: 25px;
          color: var(--primary-black);
          margin-top: 10px;
        }

        .ls-info-description {
          margin-top: 10px;
          text-align: center;
          font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
            Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          font-weight: 600;
          color: var(--primary-text-color);
          line-height: 1.5;
          padding: 0 2px;
        }

        .ls-info-description span {
          margin-top: 10px;
          text-align: center;
          font-size: 15px;
          font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
            Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          font-weight: 600;
          color: var(--primary-green);
          text-decoration: capitalize;
        }

        /* OTP Screen  */

        .otp-screen-container {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: column;
        }

        .otp-bank-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          padding: 20px 20px;
          margin-top: auto;
        }

        .otp-bank-container .header {
          padding: 0 10px;
          font-family: var(--regular-font);
          color: var(--primary-black);
        }

        .otp-form {
          width: 100%;
          margin-top: 20px;
          padding: 0px 20px;
        }

        .otp-bank-logo {
          width: 50px;
          height: 50px;
          margin-bottom: 20px;
        }

        .otp-footer {
          padding: 20px 20px;
          margin-top: auto;
          text-align: center;
          width: 100%;
          font-size: 14px;
          color: #e87c32;
        }

        /* Error Screen */

        .error-modal-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .error-container {
          width: 400px;
          padding: 50px;
          height: 300px;
          border-radius: 6px;
          background-color: white;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .error-container svg {
          margin-bottom: 30px;
        }

        .error-message {
          margin-top: 10px;
          font-size: 14px;
          color: var(--primary-gray);
        }

        .error-container h4 {
          font-size: 22px;
          font-weight: 400;
          color: var(--primary-black);
        }

        /* media queries */
        @media (max-width: 600px) {
          .close-button {
            position: absolute;
            right: 0;
            background-color: var(--disabled-color);
            border: 2px solid var(--disabled-color);
            margin: 10px;
            padding: 7px;
          }

          .modal-container {
            width: 100%;
            height: 100%;
            border-radius: 0;
          }

          .modal {
            width: 100%;
            height: 100%;
            position: relative;
          }
        }

        .brank-tagline {
          margin-top: 15px;
          background-color: black;
          padding: 10px;
          border-radius: 4px;
        }

        .secured-by-brank {
          color: white;
          text-decoration: none;
          display: flex;
          align-items: center;
        }

        .secured-by-brank p {
          margin-bottom: -2px;
          text-transform: uppercase;
          font-size: 12px;
        }

        .secured-by-brank svg {
          margin-right: 8px;
        }

        /* Loading Animation */
        .lds-ripple {
          display: inline-block;
          position: relative;
          width: 80px;
          height: 80px;
        }
        .lds-ripple div {
          position: absolute;
          border: 4px solid #fff;
          opacity: 1;
          border-radius: 50%;
          animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
        }

        .lds-ripple div:nth-of-type(2) {
          animation-delay: -0.5s;
        }

        /* animation */
        @keyframes scaleUp {
          from {
            opacity: 0;
            transform: scale(0.95);
          }
          to {
            opacity: 1;
            transform: scale(1);
          }
        }

        @keyframes lds-ripple {
          0% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 1;
          }
          100% {
            top: 0px;
            left: 0px;
            width: 72px;
            height: 72px;
            opacity: 0;
          }
        }

        .lds-roller {
          display: inline-block;
          position: relative;
          width: 80px;
          height: 80px;
        }
        .lds-roller div {
          animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          transform-origin: 40px 40px;
        }
        .lds-roller div:after {
          content: ' ';
          display: block;
          position: absolute;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background: #18181b;
          margin: -4px 0 0 -4px;
        }
        .lds-roller div:nth-of-type(1) {
          animation-delay: -0.036s;
        }
        .lds-roller div:nth-of-type(1):after {
          top: 63px;
          left: 63px;
        }
        .lds-roller div:nth-of-type(2) {
          animation-delay: -0.072s;
        }
        .lds-roller div:nth-of-type(2):after {
          top: 68px;
          left: 56px;
        }
        .lds-roller div:nth-of-type(3) {
          animation-delay: -0.108s;
        }
        .lds-roller div:nth-of-type(3):after {
          top: 71px;
          left: 48px;
        }
        .lds-roller div:nth-of-type(4) {
          animation-delay: -0.144s;
        }
        .lds-roller div:nth-of-type(4):after {
          top: 72px;
          left: 40px;
        }
        .lds-roller div:nth-of-type(5) {
          animation-delay: -0.18s;
        }
        .lds-roller div:nth-of-type(5):after {
          top: 71px;
          left: 32px;
        }
        .lds-roller div:nth-of-type(6) {
          animation-delay: -0.216s;
        }
        .lds-roller div:nth-of-type(6):after {
          top: 68px;
          left: 24px;
        }
        .lds-roller div:nth-of-type(7) {
          animation-delay: -0.252s;
        }
        .lds-roller div:nth-of-type(7):after {
          top: 63px;
          left: 17px;
        }
        .lds-roller div:nth-of-type(8) {
          animation-delay: -0.288s;
        }
        .lds-roller div:nth-of-type(8):after {
          top: 56px;
          left: 12px;
        }

        @keyframes lds-roller {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        .container {
          width: 100%;
          height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .auth-container {
          width: 500px;
          display: flex;
          flex-direction: column;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px ${theme.colors.grey} inset !important;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type='number'] {
          -moz-appearance: textfield;
        }

        a {
          text-decoration: none;
          color: var(--primary-green);
          font-size: 14px;
        }
      `}
    />
  );
};

export default GlobalStyles;
